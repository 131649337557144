// For use, select the 'Bubble_parallax' element in Strapi

import React from "react";
import { Container, Grid } from "@material-ui/core";

import ReactMarkdown from "react-markdown";

export default function Bubbleparallax(props) {
  return (
    <div className="parallax-bubbles flex items-center">
      <Container>
        <Grid container item xs={12}>
          <Grid item md={12}>
            <ReactMarkdown
              className="white-text md:text-center"
              children={props.description}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
