import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

import Homebanner from "../components/strapi/home/Homebanner";
import OneCol from "../components/strapi/structure/OneCol";
import Twocol from "../components/strapi/structure/TwoCol";
import Bubbleparallax from "../components/strapi/structure/BubbleParallax";
import Outrolocation from "../components/strapi/structure/OutroLocation";
import TestimonialSlider from "../components/testimonials/TestimonialSlider";

import Questions from "../components/strapi/pdf/questions";

export default function Home({ data }) {
  return (
    <Layout>
      <SEO title="Home" />

      <Homebanner />

      {data.allStrapiHome.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <OneCol
            title={node.home_intro.onecol_title}
            description={node.home_intro.onecol_description}
          />
          <Twocol
            title={node.home_meetteam.txtimg_title}
            description={node.home_meetteam.txtimg_description}
            blueTitle={node.home_meetteam.txtimg_bluetitle}
            blueLink={node.home_meetteam.txtimg_bluelink}
            backgroundPublic={node.home_meetteam.txtimg_img.publicURL}
            bgColor={node.home_meetteam.txtimg_bgcolor}
            order={node.home_meetteam.txtimg_order}
            rowimg={node.home_meetteam.txtimg_img.publicURL}
          />
          <Twocol
            title={node.home_selection.txtimg_title}
            description={node.home_selection.txtimg_description}
            blueTitle={node.home_selection.txtimg_bluetitle}
            blueLink={node.home_selection.txtimg_bluelink}
            bgColor={node.home_selection.txtimg_bgcolor}
            order={node.home_selection.txtimg_order}
            rowimg={node.home_selection.txtimg_img.publicURL}
          />
          <Bubbleparallax description={node.home_bubbles.bubble_description} />
          <Twocol
            title={node.home_covers.txtimg_title}
            description={node.home_covers.txtimg_description}
            blueTitle={node.home_covers.txtimg_bluetitle}
            blueLink={node.home_covers.txtimg_bluelink}
            bgColor={node.home_covers.txtimg_bgcolor}
            order={node.home_covers.txtimg_order}
            rowimg={node.home_covers.txtimg_img.publicURL}
          />
          <Twocol
            title={node.home_ownership.txtimg_title}
            description={node.home_ownership.txtimg_description}
            blueTitle={node.home_ownership.txtimg_bluetitle}
            pdf={<Questions />}
            bgColor={node.home_ownership.txtimg_bgcolor}
            order={node.home_ownership.txtimg_order}
            rowimg={node.home_ownership.txtimg_img.publicURL}
          />
          <Outrolocation outro={node.home_location.location_description} />
          <TestimonialSlider />
        </React.Fragment>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query Home {
    allStrapiHome {
      edges {
        node {
          id # id of the node
          home_intro {
            id
            onecol_title
            onecol_description
          }
          home_meetteam {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          home_selection {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          home_bubbles {
            id
            bubble_description
          }
          home_covers {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          home_ownership {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          home_satisfaction {
            id
            satisfaction_description
          }
          home_location {
            id
            location_description
          }
        }
      }
    }
  }
`;
