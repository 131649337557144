import React from "react";
import { Link } from "gatsby";
import { graphql, StaticQuery } from "gatsby";

import { Container, Grid, Button } from "@material-ui/core";
import BackgroundImage from "gatsby-background-image";

import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";

class Homebanners extends React.Component {
  render() {
    const settings = {
      infinite: true,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 8000,
      speed: 1500,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            speed: 500,
          },
        },
      ],
    };

    return (
      <Slider {...settings} className="homeslider">
        {this.props.slides.map(slide => {
          const SlideBg = [`url('${slide.txtimg_img.publicURL}')`];
          return (
            <div key={slide.id} className="slideBg">
              <BackgroundImage
                Tag={`div`}
                fluid={SlideBg}
                style={{
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "80vh",
                }}
              >
                <div className="slideContent flex items-center h-full">
                  <Container>
                    <div className="teal-bg" />
                    <Grid item md={7} className="slide-content">
                      <div className="white-text">
                        <h1>{slide.txtimg_title}</h1>
                        <h5>{slide.txtimg_subtitle}</h5>
                        <div className="button-wrapper">
                          {slide.txtimg_bluelink && (
                            <Link
                              to={`${
                                slide.txtimg_bluelink ===
                                "https://beachcomberbarrie.com/"
                                  ? "/"
                                  : slide.txtimg_bluelink
                              }`}
                            >
                              <Button variant="contained" color="secondary">
                                {slide.txtimg_bluetitle}
                              </Button>
                            </Link>
                          )}
                          {slide.txtimg_greylink && (
                            <Link to={slide.txtimg_greylink}>
                              <Button variant="contained" color="primary">
                                {slide.txtimg_greytitle}
                              </Button>
                            </Link>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Container>
                </div>
              </BackgroundImage>
            </div>
          );
        })}
      </Slider>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query MyQuery {
        strapiHome {
          home_homebanners {
            id
            txtimg_title
            txtimg_subtitle
            txtimg_bluetitle
            txtimg_bluelink
            txtimg_greytitle
            txtimg_greylink
            txtimg_img {
              publicURL
            }
          }
        }
      }
    `}
    render={data => <Homebanners slides={data.strapiHome.home_homebanners} />}
  />
);
